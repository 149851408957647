<template>
  <div>
    <div
      class="row mb-2 border row "
    >
      <div class="col-lg-2 col-md-2 d-grid text-center">
        <center>
          <br><br>
          <img
            :src="picture === '' ? require('@/assets/images/firmnoImage.png') : picture"
            class="img-fluid"
            width="100"
            heigt="100"
          >
          <br><br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="primary"
            @click="onGetFirmClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Firma Envanter</span>
          </b-button>
          <br><br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            squared
            class="mr-1 mb-1"
            variant="danger"
            @click="onDuzenleBtnClicked(r)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-30"
            />
            <span>Talep Detay</span>
          </b-button>
        </center>
      </div>
      <div class="col-lg-4 col-md-4 row align-items-center mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Firma Unvanı:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.firmInfo.firmTitle }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Pozisyon İsmi:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.position }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Personel Sınıfı:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.staffClass }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Alınacak Kişi Sayısı:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.personelCount }}
              </td>
            </tr>
            <tr>
              <th
                colspan="2"
              >
                &nbsp;
              </th>
            </tr>
            <tr>
              <th
                colspan="2"
              >
                TALEP SKORU
              </th>
            </tr>
            <tr>
              <th
                class="text-danger"
              >
                Kişisel Özellik:
              </th>
              <td
                class="text-center"
              >
                {{ r.evaluationStaffRequestSummaryViewModel.requestPersonalScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Mesleki Yeterlilik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.requestVocationalCompilanceScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Temel Yetenek:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.requestBasicAbilityScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Teknik Yeterlilik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.requestTechnicalCompilanceScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Risk:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.requestRiskScore.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-4 col-md-4 row mt-1">
        <table class="table table-bordered table-sm">
          <tbody>
            <tr>
              <th class="text-danger">
                Talep Tarihi:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.requestOpenDate.substring(0,10) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Faktör:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.factorPosition.staffPositionName }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Çalışma Şekli:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.jobType }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Görev Açıklaması:
              </th>
              <td class="text-center">
                {{ r.staffRequestSummaryViewModel.shortPositionDescription }}
              </td>
            </tr>
            <tr>
              <th
                colspan="2"
              >
                &nbsp;
              </th>
            </tr>
            <tr>
              <th
                colspan="2"
              >
                EŞLEŞEN ADAYLAR ORTALAMA SKORLARI
              </th>
            </tr>
            <tr>
              <th class="text-danger">
                Kişisel Özellik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgPersonalScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Mesleki Yeterlilik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgVocationalCompilanceScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Temel Yetenek:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgBasicAbilityScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Teknik Yeterlilik:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgTechnicalCompilanceScore.toFixed(2) }}
              </td>
            </tr>
            <tr>
              <th class="text-danger">
                Risk:
              </th>
              <td class="text-center">
                {{ r.evaluationStaffRequestSummaryViewModel.totalAvgRiskScore.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <b-row> <!-- filtre satırı -->
          <b-col>
            <b-form-radio
              v-model="deger"
              plain
              name="some-radios3"
              :value="false"
            >
              Yönlendirilmemiş Adaylar
            </b-form-radio>
          </b-col>
          <b-col>
            <b-form-radio
              v-model="deger"
              plain
              name="some-radios3"
              :value="true"
            >
              Yönlendirilmiş Adaylar
            </b-form-radio>
          </b-col>
          <b-col>
            <b-form-checkbox
              v-model="sonuc"
              :value="true"
              plain
            >
              Sonuç Kaydı Olanlar
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-group
              label="Sıralama Seçeneği"
              label-for="sorting"
            >
              <v-select
                id="sorting"
                v-model="sortSelected"
                label="title"
                :options="sortOption"
                :reduce="item => item.value"
                class="border"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <app-collapse>
          <app-collapse-item title="Eşleşen Adaylar">
            <b-row
              v-for="(ra,ix) in r.evaluationOpenPositionViewModels.filter(x=>x.evaluationState ==1 && x.redirectionState==(deger ? 1 : 0)).filter(x=>x.applicant.status == 1 ).filter(s=> s.resultState !== 0 || !sonuc)"
              :key="ix"
              class="border mb-1 mr-1"
              align-v="center"
            >
              <b-col
                cols="12"
                md="2"
              >
                <b-row align-h="center">
                  <h4>
                    <b-badge
                      v-if="ra.redirectionState==1"
                      variant="light-success"
                      class="mb-1 ml-1"
                    >
                      <feather-icon
                        icon="CheckSquareIcon"
                        class="mr-1 ml-1"
                        style="width:20px; height:20px;"
                      />
                      <span>Yönlendirilmiş Aday </span>
                    </b-badge>
                  </h4>
                </b-row>
                <b-row
                  align-h="center"
                >
                  <img
                    :src="ra.applicant.photo === '' ? require('@/assets/images/usernoImage.png') : ra.applicant.photo"
                    width="80"
                    height="80"
                    class="mb-1"
                  >
                </b-row>
                <b-row
                  v-if="ra.resultState>0"
                  align-h="center"
                >
                  <h4>
                    <b-badge
                      variant="light-danger"
                      class="mb-1 ml-1"
                    >
                      <feather-icon
                        icon="LoaderIcon"
                        class="mr-1 ml-1"
                      />
                      <span>{{ getResultText(ra.resultState) }}</span>
                    </b-badge>
                  </h4>
                </b-row>
                <b-row
                  align-h="center"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    size="sm"
                    squared
                    variant="relief-danger"
                    @click="onAdayBtnClicked(ra)"
                  >
                    <feather-icon
                      icon="UserIcon"
                      class="mr-30"
                    />
                    <span>Özgeçmiş</span>
                  </b-button>
                </b-row>
              </b-col>

              <b-col md="8">
                <b-row>
                  <!-- ilk col -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="7"
                        md="7"
                      >
                        Adı Soyadı:
                      </b-col>
                      <b-col
                        class="border"
                        cols="5"
                        md="5"
                      >
                        {{ ra.applicant.applicantName }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="7"
                        md="7"
                      >
                        Başvuru Tarihi:
                      </b-col>
                      <b-col
                        class="border"
                        cols="5"
                        md="5"
                      >
                        {{ ra.applicant.registrationDate.substring(0,10) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="7"
                        md="7"
                      >
                        Güncelleme Tarihi:
                      </b-col>
                      <b-col
                        class="border"
                        cols="5"
                        md="5"
                      >
                        {{ ra.applicant.updatedDate.substring(0,10) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="7"
                        md="7"
                      >
                        Eşleştirme Sayısı:
                      </b-col>
                      <b-col
                        class="border"
                        cols="5"
                        md="5"
                      >
                        {{ ra.applicant.evoluationCount }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="7"
                        md="7"
                      >
                        Yönlendirme Sayısı:
                      </b-col>
                      <b-col
                        class="border"
                        cols="5"
                        md="5"
                      >
                        {{ ra.applicant.redirectionCount }}
                      </b-col>
                    </b-row>
                  </b-col>

                  <!-- ikinci col -->

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Kişisel Özellik &nbsp; / &nbsp; Talep Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.totalPersonalScore.toFixed(2) }} &nbsp; / &nbsp;  %{{ oran(ra.totalPersonalScore,r.evaluationStaffRequestSummaryViewModel.requestPersonalScore) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Mesleki Yeterlilik &nbsp; / &nbsp; Talep Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.totalVocationalCompilanceScore.toFixed(2) }} &nbsp; /  &nbsp;  %{{ oran(ra.totalVocationalCompilanceScore, r.evaluationStaffRequestSummaryViewModel.requestVocationalCompilanceScore) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Temel Yetenek &nbsp; / &nbsp; Talep Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.totalBasicAbilityScore.toFixed(2) }} &nbsp; / &nbsp;  %{{ oran(ra.totalBasicAbilityScore, r.evaluationStaffRequestSummaryViewModel.requestBasicAbilityScore) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Teknik Yeterlilik &nbsp;&nbsp; / &nbsp;&nbsp; Talep Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.totalTechnicalCompilanceScore.toFixed(2) }} &nbsp; /  &nbsp;  %{{ oran(ra.totalTechnicalCompilanceScore, r.evaluationStaffRequestSummaryViewModel.requestTechnicalCompilanceScore) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Risk &nbsp; / &nbsp; Talep Risk Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.riskScore.toFixed(2) }} &nbsp; / &nbsp;  %{{ oran(ra.riskScore, r.evaluationStaffRequestSummaryViewModel.requestRiskScore) }}
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <!-- Tarih -->
                <b-row>
                  <b-col
                    v-if="ra.redirectionState==1"
                    class=" border"
                    cols="6"
                    md="6"
                  >
                    <label class="text-danger"> Yönlendirme Tarihi: </label>
                  </b-col>
                  <b-col
                    class="border"
                  >
                    {{ strToDate(ra.redirectionDate) }}
                  </b-col>
                </b-row>
                <!-- Not -->
                <b-row
                  v-if="ra.redirectionState==1"
                  class="border"
                >
                  <b-col>
                    <b-row class="text-left border">
                      <b-col v-if="ra.redirectionState==1">
                        <label class="text-danger"> Yönlendirme Notu : </label>
                      </b-col>
                    </b-row>
                    <b-row class="text-left border text-small">
                      <b-col v-if="ra.redirectionState==1">
                        <p style="font-size:10px;">
                          {{ ra.redirectionNote }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                md="2"
                class="mtl-1"
                align-h="center"
              >
                <b-col
                  cols="12"
                  md="11"
                  align-h="center"
                  class="mt-2"
                >
                  <b-row
                    align-h="center"
                    align-v="center"
                  >
                    <b-button
                      v-if="ra.redirectionState!=1"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      block
                      class="mb-1"
                      variant="relief-success"
                      @click="onYonlendirAday(ra)"
                    >
                      <feather-icon
                        icon="ArrowUpRightIcon"
                        class="mr-30"
                      />
                      <span>Yönlendir </span>
                    </b-button>
                    <b-button
                      v-if="ra.redirectionState==1"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      block
                      class="mb-1"
                      variant="relief-danger"
                      @click="onYonlendirIptalAday(ra)"
                    >
                      <feather-icon
                        icon="ArrowUpRightIcon"
                        class="mr-30"
                      />
                      <span>Yönlendirmeden Çıkart </span>
                    </b-button>
                  </b-row>
                  <b-row
                    align-h="center"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      block
                      class="mt-1 mb-1"
                      variant="relief-primary"
                      :disabled="ra.redirectionState==0"
                      @click="onNoteBtnClicked(ra)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-30"
                      />
                      <span>Not Yaz</span>
                    </b-button>
                    <b-button
                      v-if="ra.redirectionState != 0"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      block
                      class="mb-1"
                      variant="relief-success"
                      @click="onResultBtnClicked(ra)"
                    >
                      <feather-icon
                        icon="FeatherIcon"
                        class="mr-30"
                      />
                      <span>Sonuç Kaydı oluştur </span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      size="sm"
                      squared
                      block
                      class="mb-1"
                      variant="relief-warning"
                      @click="onBtnAdayPasif(ra)"
                    >
                      <feather-icon
                        icon="UserXIcon"
                        class="mr-30"
                      />
                      <span>Adayı Pasif Yap</span>
                    </b-button>
                  </b-row>
                </b-col>
                <b-col
                  cols="12"
                  md="1"
                >
                  &nbsp;
                </b-col>
              </b-col>

            </b-row>
          </app-collapse-item>
          <app-collapse-item title="Elenen Adaylar">
            <b-row
              v-for="(ra,ix) in r.evaluationOpenPositionViewModels.filter(x=>x.evaluationState == 0 && x.redirectionState==(deger ? 2 : 0)).filter(x=>x.applicant.status == 1 ).filter(s=> s.resultState !== 0 || !sonuc)"
              :key="ix"
              class="border mb-1 mr-1"
              align-v="center"
            >
              <b-col>
                <b-row>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-row
                      align-h="center"
                    >
                      <h4>
                        <b-badge
                          v-if="ra.redirectionState==2"
                          variant="light-success"
                          class="mb-1 ml-1"
                          align-v="center"
                        >
                          <feather-icon
                            icon="CheckSquareIcon"
                            class="mr-1 ml-1"
                            style="width:20px; height:20px;"
                          />
                          <span align-v="center">Şartlı Yönlendirilmiş Aday </span>
                        </b-badge>
                      </h4>
                    </b-row>
                    <b-row
                      align-h="center"
                    >
                      <img
                        :src="ra.applicant.photo === '' ? require('@/assets/images/usernoImage.png') : ra.applicant.photo"
                        width="80"
                        height="80"
                        class="mb-1"
                      >
                    </b-row>
                    <b-row
                      v-if="ra.resultState>0"
                      align-h="center"
                    >
                      <h4>
                        <b-badge
                          variant="light-danger"
                          class="mb-1 ml-1"
                        >
                          <feather-icon
                            icon="LoaderIcon"
                            class="mr-1 ml-1"
                          />
                          <span>{{ getResultText(ra.resultState) }}</span>
                        </b-badge>
                      </h4>
                    </b-row>
                    <b-row
                      align-h="center"
                    >
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        squared
                        variant="relief-danger"
                        @click="onAdayBtnClicked(ra)"
                      >
                        <feather-icon
                          icon="UserIcon"
                          class="mr-30"
                        />
                        <span>Özgeçmiş</span>
                      </b-button>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-row>
                      <b-col class="border text-danger">
                        Adı Soyadı:
                      </b-col>
                      <b-col class="border">
                        {{ ra.applicant.applicantName }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="border text-danger">
                        Başvuru Tarihi:
                      </b-col>
                      <b-col class="border">
                        {{ ra.applicant.registrationDate.substring(0,10) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="border text-danger">
                        Güncelleme Tarihi:
                      </b-col>
                      <b-col class="border">
                        {{ ra.applicant.updatedDate.substring(0,10) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="border text-danger">
                        Eşleştirme Sayısı:
                      </b-col>
                      <b-col class="border">
                        {{ ra.applicant.evoluationCount }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="border text-danger">
                        Yönlendirme Sayısı:
                      </b-col>
                      <b-col class="border">
                        {{ ra.applicant.redirectionCount }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Kişisel Özellik Skoru &nbsp;&nbsp; / &nbsp;&nbsp; Talep Kişisel Özellik Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.totalPersonalScore.toFixed(2) }} &nbsp; &nbsp; / &nbsp; &nbsp;  %{{ oran(ra.totalPersonalScore,r.evaluationStaffRequestSummaryViewModel.requestPersonalScore) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Mesleki Yeterlilik &nbsp;&nbsp; / &nbsp;&nbsp; Talep Mesleki Yeterlilik Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.totalVocationalCompilanceScore.toFixed(2) }} &nbsp; &nbsp; / &nbsp; &nbsp;  %{{ oran(ra.totalVocationalCompilanceScore, r.evaluationStaffRequestSummaryViewModel.requestVocationalCompilanceScore) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Temel Yetenek &nbsp;&nbsp; / &nbsp;&nbsp; Talep Temel Yetenek Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.totalBasicAbilityScore.toFixed(2) }} &nbsp; &nbsp; / &nbsp; &nbsp;  %{{ oran(ra.totalBasicAbilityScore, r.evaluationStaffRequestSummaryViewModel.requestBasicAbilityScore) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Teknik Yeterlilik &nbsp;&nbsp; / &nbsp;&nbsp; Talep Teknik Yeterlilik Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.totalTechnicalCompilanceScore.toFixed(2) }} &nbsp; &nbsp; / &nbsp; &nbsp;  %{{ oran(ra.totalTechnicalCompilanceScore, r.evaluationStaffRequestSummaryViewModel.requestTechnicalCompilanceScore) }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        class="border text-danger"
                        cols="8"
                        md="8"
                      >
                        Risk &nbsp;&nbsp; / &nbsp;&nbsp; Talep Risk Oranı :
                      </b-col>
                      <b-col
                        class="border"
                        cols="4"
                        md="4"
                      >
                        {{ ra.riskScore.toFixed(2) }} &nbsp; &nbsp; / &nbsp; &nbsp;  %{{ oran(ra.riskScore, r.evaluationStaffRequestSummaryViewModel.requestRiskScore) }}
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mtl-1"
                    align-h="center"
                  >
                    <b-col
                      cols="12"
                      md="11"
                      align-h="center"
                    >
                      <b-row
                        align-h="center"
                      >
                        <b-button
                          v-if="ra.redirectionState===0"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          squared
                          block
                          class="mb-1"
                          variant="relief-success"
                          @click="onSartliYonlendirAday(ra)"
                        >
                          <feather-icon
                            icon="ArrowUpRightIcon"
                            class="mr-30"
                          />
                          <span>Şartlı Yönlendir</span>
                        </b-button>
                        <b-button
                          v-if="ra.redirectionState==2"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          squared
                          block
                          class="mb-1"
                          variant="relief-danger"
                          @click="onYonlendirIptalAday(ra)"
                        >
                          <feather-icon
                            icon="ArrowUpRightIcon"
                            class="mr-30"
                          />
                          <span>Yönlendirmeden Çıkart </span>
                        </b-button>
                      </b-row>
                      <b-row
                        v-if="ra.redirectionState>0"
                        class="border"
                      >
                        <b-col>
                          <b-row class="text-left border">
                            <b-col v-if="ra.redirectionState>0">
                              <label class="text-danger"> Yönlendirme: </label>
                            </b-col>
                            <b-col> {{ strToDate(ra.redirectionDate) }} </b-col>
                          </b-row>
                          <b-row class="text-left border">
                            <b-col v-if="ra.redirectionState>0">
                              <label class="text-danger"> Yönlendirme Notu : </label>
                            </b-col>
                          </b-row>
                          <b-row class="text-left border text-small">
                            <b-col v-if="ra.redirectionState>0">
                              <p style="font-size:10px;">
                                {{ ra.redirectionNote }}
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row
                        align-h="center"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          squared
                          block
                          class="mb-1"
                          variant="relief-primary"
                          @click="onNoteBtnClicked(ra)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            class="mr-30"
                          />
                          <span>Not Yaz</span>
                        </b-button>
                      </b-row>
                      <b-row>
                        <b-button
                          v-if="ra.redirectionState != 0"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          squared
                          block
                          class="mb-1"
                          variant="relief-success"
                          @click="onResultBtnClicked(ra)"
                        >
                          <feather-icon
                            icon="FeatherIcon"
                            class="mr-30"
                          />
                          <span>Sonuç Kaydı oluştur </span>
                        </b-button>
                      </b-row>
                      <b-row
                        align-h="center"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          squared
                          block
                          class="mb-1"
                          variant="relief-warning"
                          @click="onBtnAdayPasif(ra)"
                        >
                          <feather-icon
                            icon="UserXIcon"
                            class="mr-30"
                          />
                          <span>Adayı Pasif Yap</span>
                        </b-button>
                      </b-row>
                    </b-col>
                  </b-col>
                </b-row>
                <b-row> <!-- ELENME SEBEBİ -->
                  <b-col
                    cols="12"
                    md="6"
                  > &nbsp;
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-row class="border">
                      <center>
                        <h3> &nbsp; Elenme Nedenleri</h3>
                      </center>
                    </b-row>
                    <b-row>
                      <b-col class="border text-danger">
                        Kişisel Özellik
                      </b-col>
                      <b-col class="border">
                        <label class="text-danger"> Neden sayısı :{{ ra.personalRejectReasons.length }} </label>
                        <br>
                        <b-row
                          v-for="(neden,iz) in ra.personalRejectReasons"
                          :key="iz"
                        >
                          &nbsp; {{ nedenCeviri(neden) }}
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="border text-danger">
                        Mesleki Yeterlilik
                      </b-col>
                      <b-col class="border">
                        <label class="text-danger"> Neden sayısı :{{ ra.vocationalRejectReasons.length }}</label>
                        <br>
                        <b-row
                          v-for="(neden,iq) in ra.vocationalRejectReasons"
                          :key="iq"
                        >
                          &nbsp; {{ nedenCeviri(neden) }}
                        </b-row>

                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </div>
    </div>
    <b-modal
      id="noteadd-modal"
      cancel-variant="outline-secondary"
      ok-title="Kaydet"
      cancel-title="İptal"
      size="lg"
      centered
      rows="5"
      no-close-on-backdrop
      title="Yönlendirme Notu"
      @ok="onModalSaveNote"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group>
          <label for="NoteName">Yönlendirme Notu:</label>
          <b-form-textarea
            id="NoteName"
            v-model="redirectionNote"
            type="text"
            placeholder="Yönlendirme Notu"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="addresult-modal"
      cancel-variant="outline-secondary"
      ok-title="Kaydet"
      cancel-title="İptal"
      size="lg"
      centered
      rows="5"
      no-close-on-backdrop
      title="Sonuç Kaydı Oluşturma"
      @ok="onModalSaveResult"
      @cancel="onModalCancelledResult"
    >
      <b-form>
        <b-form-group>
          <label for="ResultDate">Durum Tarihi:</label>
          <b-form-input
            id="NoteName"
            v-model="resultDate"
            type="text"
            disabled
          />
        </b-form-group>
        <b-form-group>
          <label for="durum">Durum: </label>
          <v-select
            v-model="resultState"
            :options="resultOptions"
            label="evaluationResultStateName"
            :reduce="item=> item.evaluationResultStateId "
            class="border"
          />
        </b-form-group>
        <b-form-group>
          <label for="ResultNote">Durum Notu:</label>
          <b-form-textarea
            id="ResultNote"
            v-model="resultText"
            type="text"
            placeholder="Sonuç Durum Notu"
          />
        </b-form-group>
        <!-- <b-form-group>
          <label for="NegResultNote">Olumsuz Durum Notu:</label>
          <b-form-textarea
            id="NegNoteName"
            v-model="resultNegativeText"
            type="text"
            placeholder="Olumsuz Notu"
            :disabled="resultState==1 || resultState==4 || resultState==5 || resultState==6 || resultState==7"
          />
        </b-form-group> -->
        <b-form-group>
          <label for="NegativeResultReason">Olumsuzluk Nedeni: </label>
          <v-select
            v-model="resultNegativeText"
            :options="negativetOptions"
            label="negativeResultDescription"
            :disabled="resultState==1 || resultState==4 || resultState==5 || resultState==6 || resultState==7"
            :reduce="nrrId=> nrrId.negativeResultDescription"
            class="border"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, VBModal, BRow, BCol, BBadge, BForm, BFormTextarea, BFormGroup, BFormRadio, BFormCheckbox, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiEval from '@/api/islemler/Evaluation'
import * as apiAday from '@/api/islemler/applicant/applicantInfo'
import * as apiNRR from '@/api/tanimlar/VKT/NegativeResultReasons'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BBadge,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormInput,
    // BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      evalutationStaffingId: 0,
      applicantId: 0,
      redirectionNote: '',
      applicantName: '',
      redirectionState: 0,
      requestPosition: '',
      pageLength: 15,
      list: [],
      deger: true,
      sonuc: false,
      searchTerm: '',
      requestScores: [],
      requestId: this.$route.params.requestId,
      r: this.$route.params.r,
      picture: '',
      sortSelected: { title: 'Kişisel Özellik', value: 'totalPersonalScore' },
      sortOption: [
        { title: 'Kişisel Özellik', value: 'totalPersonalScore' },
        { title: 'Mesleki Yeterlilik', value: 'totalVocationalCompilanceScore' },
        { title: 'Temel Yetenek', value: 'totalBasicAbilityScore' },
        { title: 'Teknik Yeterlilik', value: 'totalTechnicalCompilanceScore' },
        { title: 'Risk', value: 'riskScore' },
      ],
      resultOptions: [],
      resultDate: '',
      resultState: 0,
      resultNegativeText: '',
      resultText: '',
      openposition: {},
      retval: [],
      adaytalep: [],
      adaybilgi: { essay: 0, yonsay: 0 },
      negativetOptions: [],
    }
  },
  computed: {
  },
  watch: {
    sortSelected() {
      this.r.evaluationOpenPositionViewModels.sort((a, b) => b[`${this.sortSelected}`] - a[`${this.sortSelected}`])
    },
  },
  beforeMount() {
    this.getSRequest()
    this.resultStateText()
    this.picture = this.r.staffRequestSummaryViewModel.firmInfo.firmLogo
  },
  created() {
  },
  methods: {
    getSRequest() {
      apiEval.EvaluationStaffRequestSummary(this.requestId).then(res => {
        this.r = res.data.entity
        this.r.evaluationOpenPositionViewModels.forEach(element => {
          apiEval.GetEvaluationOpenPositionsByApplicantId(element.applicant.applicantId).then(ret => {
            let essay = 0
            let yonsay = 0
            essay = ret.data === null ? 0 : ret.data.count
            yonsay = ret.data === null ? 0 : ret.data.list.filter(x => x.redirectionState === 1).length
            Object.assign(element.applicant, { evoluationCount: essay })
            Object.assign(element.applicant, { redirectionCount: yonsay })
          })
        })
        this.r.evaluationOpenPositionViewModels.sort((a, b) => b[`${this.sortSelected}`] - a[`${this.sortSelected}`])
        this.picture = this.r.staffRequestSummaryViewModel.firmInfo.firmLogo
      })
    },
    getApplicantData(aid) {
      let say = 0
      apiEval.GetEvaluationOpenPositionsByApplicantId(aid).then(ret => {
        this.adaytalep = ret.data.list
        say = ret.data.count
        return say
      })
    },
    getnegativetOptions() {
      apiNRR.GetNegativeResultReasons().then(ret => {
        this.negativetOptions = ret.data.list
      })
    },
    onUpdateBtnClicked() {
    },
    onDuzenleBtnClicked(row) {
      this.$router.push({ name: 'yeni-talep', params: { firmStaffRequestId: row.staffRequestSummaryViewModel.staffRequestId, firmInfo: row.staffRequestSummaryViewModel.firmInfo } })
    },
    onGetFirmClicked(row) {
      this.$router.push({ name: 'firma-kart', params: { firmId: row.staffRequestSummaryViewModel.firmInfo.firmInfoId } })
    },
    onAdayBtnClicked(row) {
      this.$router.push({ name: 'aday-kayit', params: { applicantId: row.applicant.applicantId } })
    },
    strToDate(st) {
      if (st === undefined || st === null) return st
      if (st.length < 10) return st
      let yil = ''
      yil = st.substring(0, 4)
      let ay = ''
      ay = st.substring(5, 7)
      let gun = ''
      gun = st.substring(8, 10)
      return `${gun}.${ay}.${yil}`
    },
    oran(a, b) {
      const yuzde = (a / b) * 100
      return yuzde.toFixed(2)
    },
    nedenCeviri(ndn) {
      let sebep = ''
      switch (ndn) {
        case 'GenderPoints': sebep = 'Cinisyet'
          break
        case 'MaritalStatusPoints': sebep = 'Medeni hal'
          break
        case 'BirthDatePoints': sebep = 'Yaşı'
          break
        case 'HeightCmPoints': sebep = 'Boyu'
          break
        case 'WeightKgPoints': sebep = 'Kilosu'
          break
        case 'TownPoints': sebep = 'İlçe uyumsuzluğu'
          break
        case 'MilitaryStatusPoints': sebep = 'Askerlik durumu'
          break
        case 'IsNotDisabledPoints': sebep = 'Engellilik'
          break
        case 'IsNotSmokerPoints': sebep = 'Sigara kullanlımı'
          break
        case 'CanTravelPoints': sebep = 'Seyahat engeli'
          break
        case 'HasWorkShiftsPoints': sebep = 'Vardiyalı çalışamama'
          break
        case 'HasReferencesPoints': sebep = 'Referans eksiği'
          break
        case 'HasNotCriminalRecordPoints': sebep = 'Sabıka Kaydı'
          break
        case 'CanDrivePoints': sebep = 'Aktif araç kullanımı'
          break
        case 'DriverLicenseTypePoints': sebep = 'Ehliyet yetersizliği'
          break
        case 'EducationLevelPoints': sebep = 'Tahsil seviyesi'
          break
        case 'VocationCollegePoints': sebep = 'Meslek yüksek okulu bölümü'
          break
        case 'VocationHighSchoolPoints': sebep = 'Meslek lisesi bölümü'
          break
        case 'UniversityPoints': sebep = 'Üniversite bölümü'
          break
        case 'PostgraduatePoints': sebep = 'Yüksek lisans bölümü'
          break
        case 'ComputerKnowledge1Points': sebep = 'Bilgisayar bilgisi 1'
          break
        case 'ComputerKnowledge2Points': sebep = 'Bilgisayar bilgisi 2'
          break
        case 'ComputerKnowledge3Points': sebep = 'Bilgisayar bilgisi 3'
          break
        case 'ComputerKnowledge4Points': sebep = 'Bilgisayar bilgisi 4'
          break
        case 'ComputerKnowledge5Points': sebep = 'Bilgisayar bilgisi 5'
          break
        case 'LanguageKnowLedge1Points': sebep = 'Yabancı dil bilgisi 1'
          break
        case 'LanguageKnowledge2Points': sebep = 'Yabancı dil bilgisi 2'
          break
        case 'LanguageKnowledge3Points': sebep = 'Yabancı dil bilgisi 3'
          break
        case 'LanguageKnowledge4Points': sebep = 'Yabancı dil bilgisi 4'
          break
        case 'LanguageKnowledge5Points': sebep = 'Yabancı dil bilgisi 5'
          break
        case 'Certificate1Points': sebep = 'Sertifika 1 eksik'
          break
        case 'Certificate2Points': sebep = 'Sertifika 2 eksik'
          break
        case 'Certificate3Points': sebep = 'Sertifika 3 eksik'
          break
        case 'Certificate4Points': sebep = 'Sertifika 4 eksik'
          break
        case 'Certificate5Points': sebep = 'Sertifika 5 eksik'
          break
        case 'TotalExperienceYearPoints': sebep = 'Toplam tecrübe yılı'
          break
        case 'SectorExperience1Points': sebep = 'Sektör 1 uyumsuz'
          break
        case 'SectorExperience2Points': sebep = 'Sektör 2 uyumsuz'
          break
        case 'SectorExperience3Points': sebep = 'Sektör 3 uyumsuz'
          break
        case 'SectorExperience4Points': sebep = 'Sektör 4 uyumsuz'
          break
        case 'SectorExperience5Points': sebep = 'Sektör 5 uyumsuz'
          break
        case 'PositionExperience1Points': sebep = 'Pozisyon 1 tecrübesi eksik'
          break
        case 'PositionExperience2Points': sebep = 'Pozisyon 2 tecrübesi eksik'
          break
        case 'PositionExperience3Points': sebep = 'Pozisyon 3 tecrübesi eksik'
          break
        case 'PositionExperience4Points': sebep = 'Pozisyon 4 tecrübesi eksik'
          break
        case 'PositionExperience5Points': sebep = 'Pozisyon 5 tecrübesi eksik'
          break
        case 'NumericalAbilityPoints': sebep = 'Sayısal yetenek'
          break
        case 'AnalyticalAbilityPoints': sebep = 'Analitik düşünme'
          break
        case 'AttentionAbilityPoints': sebep = 'Dikkat yeteneği'
          break
        case 'LearnAbilityPoints': sebep = 'Öğrenebilme yeteneği'
          break
        case 'ApplyingAbilityPoints': sebep = 'Öğrendiğini uygulayabilme'
          break
        case 'InnovationAbilityPoints': sebep = 'Yenilik ve değişime açıklık'
          break
        case 'CommunicationAbilityPoints': sebep = 'İletişim ve kendini geliştirme'
          break
        case 'SolutionAbilityPoints': sebep = 'Problem çözebilme'
          break
        case 'PlanningAbilityPoints': sebep = 'Planlama'
          break
        case 'CoordinationAbilityPoints': sebep = 'Koordinasyon'
          break
        case 'ObeyingAbilityPoints': sebep = 'Emir komuta'
          break
        case 'ControllingAbilityPoints': sebep = 'Kontrol denetim'
          break
        case 'DesignAbilityPoints': sebep = 'Tasarım Kurgu'
          break
        case 'RepresentationAbilityPoints': sebep = 'Temsil'
          break
        case 'FinancialRiskPoints': sebep = 'Mali risk'
          break
        case 'LegalRiskPoints': sebep = 'Yasal risk'
          break
        case 'MedicalRiskPoints': sebep = 'Sağlık riski'
          break
        case 'LifeRiskPoints': sebep = 'Hayati risk'
          break
        default:
          sebep = ndn
      }
      return sebep
    },
    resultStateText() {
      apiEval.GetEvaluationResultStates().then(res => {
        this.retval = res.data.list
      })
    },
    getResultText(id) {
      // if (this.retval[id] === null) return 'YOK YOK'
      let sondurum = ''
      switch (id) {
        case 1: case 7: sondurum = 'Belirsiz'
          break
        case 2: case 3: case 4: sondurum = 'Olumsuz'
          break
        case 5: case 6: sondurum = 'Olumlu'
          break
        default: sondurum = 'Belirsiz'
      }
      return sondurum
      // return this.retval[id].evaluationResultStateName
    },
    onYonlendirAday(row) {
      this.evalutationStaffingId = row.evalutationStaffingId
      this.redirectionNote = row.redirectionNote
      this.applicantId = row.applicant.applicantId
      this.applicantName = row.applicant.applicantName
      this.redirectionState = row.redirectionState
      this.requestPosition = row.staffRequest.position
      apiEval.EvaluationRedirectApplicant(this.evalutationStaffingId).then(() => {
        this.getSRequest()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Aday Yönlendirme',
            text: `${this.applicantName} adayı ${this.requestPosition} talebine yönlendirilmiştir.`,
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
      })
      this.$bvModal.show('noteadd-modal')
    },
    onSartliYonlendirAday(row) {
      this.evalutationStaffingId = row.evalutationStaffingId
      this.redirectionNote = row.redirectionNote
      this.applicantId = row.applicant.applicantId
      this.applicantName = row.applicant.applicantName
      this.redirectionState = row.redirectionState
      this.requestPosition = row.staffRequest.position
      apiEval.ConditionalRedirectApplicant(this.evalutationStaffingId).then(() => {
        this.getSRequest()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Aday Yönlendirme',
            text: `${this.applicantName} adayı ${this.requestPosition} talebine yönlendirilmiştir.`,
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
      })
      this.$bvModal.show('noteadd-modal')
    },
    onBtnAdayPasif(row) {
      const { applicant } = row
      // const { evalutationStaffingId } = row
      applicant.status = 2
      apiAday.UpdateApplicant(applicant).then(() => {
        this.getSRequest()
      })
    },
    onBtnClicked() {},
    onResultBtnClicked(row) {
      this.evalutationStaffingId = row.evalutationStaffingId
      const today = new Date()
      this.resultDate = row.resultDate == null ? `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}` : row.resultDate
      this.resultState = row.resultState
      this.resultText = row.resultText
      this.applicantId = row.applicant.applicantId
      this.applicantName = row.applicant.applicantName
      this.resultNegativeText = row.resultNegativeText === null ? '' : row.resultNegativeText
      this.openposition = row
      this.getnegativetOptions()
      apiEval.GetEvaluationResultStates().then(res => {
        this.resultOptions = res.data.list.filter(x => x.evaluationResultStateId !== 8)
        this.$bvModal.show('addresult-modal')
      })
    },
    onNoteBtnClicked(row) {
      this.evalutationStaffingId = row.evalutationStaffingId
      this.redirectionNote = row.redirectionNote
      this.applicantId = row.applicant.applicantId
      this.applicantName = row.applicant.applicantName
      this.redirectionState = row.redirectionState
      this.requestPosition = row.staffRequest.position
      this.$bvModal.show('noteadd-modal')
    },
    onModalCancelled() {},
    onYonlendirIptalAday(row) {
      this.evalutationStaffingId = row.evalutationStaffingId
      this.applicantName = row.applicant.applicantName
      this.requestPosition = row.staffRequest.position
      apiEval.EvaluationIndirectApplicant(this.evalutationStaffingId).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Aday Yönlendirme İptali',
            text: `${this.applicantName} adayı ${this.requestPosition} talebine yönlendirilmesi iptal edilmiştir..`,
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        this.getSRequest()
      })
    },
    onModalSaveNote() {
      apiEval.EvaluationAddNoteToOpenPositionItem(this.evalutationStaffingId, this.redirectionNote).then(() => {
        this.getSRequest()
      })
    },
    onModalSaveResult() {
      this.openposition.resultDate = this.resultDate
      this.openposition.resultState = this.resultState
      this.openposition.resultText = this.resultText
      this.openposition.resultNegativeText = this.resultNegativeText
      apiEval.EvaluationAddResultToOpenPositionItem(this.evalutationStaffingId, this.resultDate, this.resultState, this.resultText, this.resultNegativeText).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Aday Sonuç kayıdı',
            text: `${this.applicantName} adayı ${this.openposition.staffRequest.position} talebi için sonuç kaydı girlmiştir.`,
            icon: 'PlusSquareIcon',
            variant: 'success',
          },
        })
        this.getSRequest()
      })
    },
    onModalCancelledResult() {},
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
   @import '@core/scss/vue/libs/vue-select.scss';
</style>
