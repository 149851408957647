import request from '@/utils/request'

export function EvaluateStaffRequest(staffRequestId) {
  return request({
    url: `/api/Evaluation/EvaluateStaffRequest/${staffRequestId}`,
    method: 'get',
  })
}

export function GetApplicantEvaluationCvPointsByApplicantId(applicantId) {
  return request({
    url: `/api/Evaluation/GetApplicantEvaluationCvPointsByApplicantId/${applicantId}`,
    method: 'get',
  })
}

export function GetApplicantEvaluationCvPointsSummaryByApplicantId(applicantId) {
  return request({
    url: `/api/Evaluation/GetApplicantEvaluationCvPointsSummaryByApplicantId/${applicantId}`,
    method: 'get',
  })
}

export function GetStaffRequestEvaluationPointsByStaffRequestId(staffRequestId) {
  return request({
    url: `/api/Evaluation/GetStaffRequestEvaluationPointsByStaffRequestId/${staffRequestId}`,
    method: 'get',
  })
}

export function GetSummaryPointsByStaffRequestId(staffRequestId) {
  return request({
    url: `/api/Evaluation/GetSummaryPointsByStaffRequestId/${staffRequestId}`,
    method: 'get',
  })
}

export function GetEvaluatedApplicantsByStaffRequestId(staffRequestId) {
  return request({
    url: `/api/Evaluation/GetEvaluatedApplicantsByStaffRequestId/${staffRequestId}`,
    method: 'get',
  })
}

export function EvaluationStaffRequestSummaryHolderViewModel(status) {
  return request({
    url: `/api/Evaluation/EvaluationStaffRequestSummaryHolderViewModel/${status}`,
    method: 'get',
  })
}

export function EvaluationRedirectApplicant(evalutationStaffingId) {
  return request({
    url: `/api/Evaluation/EvaluationRedirectApplicant?openPositionId=${evalutationStaffingId}`,
    method: 'post',
  })
}
// ConditionalRedirectApplicant
export function ConditionalRedirectApplicant(evalutationStaffingId) {
  return request({
    url: `/api/Evaluation/ConditionalRedirectApplicant?openPositionId=${evalutationStaffingId}`,
    method: 'post',
  })
}

export function EvaluationIndirectApplicant(evalutationStaffingId) {
  return request({
    url: `/api/Evaluation/EvaluationIndirectApplicant?openPositionId=${evalutationStaffingId}`,
    method: 'post',
  })
}

export function EvaluationStaffRequestSummary(staffRequestId) {
  return request({
    url: `/api/Evaluation/EvaluationStaffRequestSummary/${staffRequestId}`,
    method: 'get',
  })
}

export function EvaluationAddNoteToOpenPositionItem(evalutationStaffingId, notes) {
  return request({
    url: `/api/Evaluation/EvaluationAddNoteToOpenPositionItem?openPositionId=${evalutationStaffingId}&notes=${notes}`,
    method: 'post',
  })
}

export function EvaluationAddResultToOpenPositionItem(openPositionId, resultDate, resultState, resultText, resultNegativeText) {
  return request({
    url: `/api/Evaluation/EvaluationAddResultToOpenPositionItem?openPositionId=${openPositionId}&resultDate=${resultDate}&resultState=${resultState}&resultText=${resultText}&resultNegativeText=${resultNegativeText}`,
    method: 'post',
  })
}

export function GetEvaluationResultStates() {
  return request({
    url: '/api/EvaluationResultStates/GetEvaluationResultStates',
    method: 'get',
  })
}

export function GetEvaluationResultState(id) {
  return request({
    url: `/api/EvaluationResultStates/GetEvaluationResultState/${id}`,
    method: 'get',
  })
}
export function GetEvaluationOpenPositionsByApplicantId(applicantId) {
  return request({
    url: `/api/Evaluation/GetEvaluationOpenPositionsByApplicantId?applicantId=${applicantId}`,
    method: 'get',
  })
}
