import request from '@/utils/request'

export function GetNegativeResultReasons() {
  return request({
    url: '/api/NegativeResultReasons/GetNegativeResultReasons',
    method: 'get',
  })
}

export function GetNegativeResultReasonId(data) {
  return request({
    url: `/api/NegativeResultReasons/GetNegativeResultReasonId/${data}`,
    method: 'get',
  })
}
